import { Box, Button, Container, Divider, Theme, Typography } from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { SxProps, SystemProps } from '@material-ui/system';
import {faBinoculars, faQuestionCircle, faSatellite, faSearch, faTelescope} from '@fortawesome/pro-light-svg-icons';

export interface PlanFeature {
  text: string;
  info: string;
}

interface PlanProps {
  plan: string;
  icon: IconDefinition;
  cost: string;
  tag: string;
  blurb: string;
  recommended?: boolean;
  features: PlanFeature[];
  containerProps?: SystemProps<Theme> & {
    sx?: SxProps<Theme>;
  };
}

function Plan({ plan, icon, cost, tag, blurb, features, recommended, containerProps }: PlanProps) {
  return (
    <Box display="flex" flexDirection="column" flex={1} {...containerProps}>
      <Box display="flex" justifyContent="center">
        <Box
          p={2}
          sx={{
            backgroundColor: (t) => t.palette.primary.main,
            borderRadius: '100%',
            color: (t) => t.palette.background.paper,
            zIndex: 1,
            height: (t) => t.spacing(10),
            width: (t) => t.spacing(10),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FontAwesomeIcon icon={icon} size="3x" />
        </Box>
      </Box>
      <Box
        flex={1}
        p={2}
        sx={{
          backgroundColor: (t) => t.palette.background.paper,
          border: (t) => `1px solid ${recommended ? t.palette.primary.main : t.palette.divider}`,
          marginTop: { xs: -6, xl: recommended ? -12 : -6 },
          marginBottom: { xs: 0, xl: recommended ? -6 : 0 },
        }}
      >
        <Box sx={{ textAlign: 'center', paddingTop: { xs: 6, md: recommended ? 12 : 6 } }}>
          <Typography variant="subtitle2" sx={{ color: (t) => t.palette.primary.main }}>
            {plan}
          </Typography>
          <Typography variant="subtitle1" sx={{ paddingTop: (t) => t.spacing(2) }}>
            {cost}
          </Typography>
          <Typography variant="body1">{tag}</Typography>
          <Typography variant="body2" sx={{ color: (t) => t.palette.primary.main, paddingTop: (t) => t.spacing(2) }}>
            {blurb}
          </Typography>
        </Box>
        <Box pt={2}>
          {features.map((it) => (
            <Box key={it.text} pt={2} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography flex={1} variant="body2" textAlign="left">
                {it.text}
              </Typography>
              <Box>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Box>
            </Box>
          ))}
        </Box>
        <Box pt={6} pb={4}>
          <Button fullWidth variant="contained" size="large" sx={{ pointerEvents: 'initial' }}>
            Get Started
          </Button>
          {recommended && (
            <Typography variant="body1" sx={{ display: { xs: 'none', md: 'block' }, color: (t) => t.palette.primary.main, paddingTop: (t) => t.spacing(3), textAlign: 'center' }}>
              Recommended
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

function Pricing() {
  return (
    <Box id="pricing" pt={5} sx={{ textAlign: 'center', background: (t) => t.palette.grey['300'] }}>
      <Container maxWidth="xl">
        <Box>
          <Typography pb={1} variant="h2" sx={{ fontSize: '1.25em', color: (t) => t.palette.primary.main }}>
            Pricing
          </Typography>
          <Typography pb={2} variant="h3" sx={{ fontSize: '1.5em' }}>
            Find the right plan for your needs.
          </Typography>
          <Divider sx={{ margin: '0 35%' }} />
        </Box>
        <Box sx={{ display: "flex", flexDirection: { xs: "column", xl: "row" }, paddingTop: { xs: 2, md: 0, xl: 2 }, paddingBottom: { xs: 2, xl: 8 } }}>
          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, paddingTop: { xs: 0, md: 2 }, paddingRight: { xs: 0, xl: 2 } }}>
            <Plan
              containerProps={{ sx: { paddingTop: { xs: 2, md: 0 }, paddingRight: { xs: 0, md: 2 } }}}
              plan="Starter"
              icon={faSearch}
              cost="Free"
              tag="Free Forever"
              blurb="Amazing for users getting started with the basics"
              features={[
                { text: '1 User', info: 'A solo user, no team management' },
                { text: '1 AWS Account', info: 'Only 1 AWS Account can be visualized' },
                { text: '2 Regions', info: 'Search restricted to 2 regions plus global resources' },
                { text: 'Resource Inventory', info: 'Search, filter and compare resources' },
                { text: 'Architecture Visualisation', info: 'Visualise resources and how they relate to one another' },
                { text: 'Trial Period of Cost & Usage Analysis', info: '1 month of cost & usage analysis periodically updated until trial ends.' },
              ]}
            />
            <Plan
              containerProps={{ sx: { paddingTop: { xs: 2, md: 0 } }}}
              plan="Individual"
              icon={faBinoculars}
              cost="£25"
              tag="Billed Monthly"
              blurb="Great for individual users requiring advanced features"
              features={[
                { text: '1 User', info: 'A solo user, no team management' },
                { text: '3 AWS Accounts', info: 'Only 3 AWS Account can be visualized' },
                { text: 'All Regions', info: 'Search resources from all over the globe' },
                { text: 'Resource Inventory', info: 'Search, filter and compare resources across accounts' },
                { text: 'Architecture Visualisation', info: 'Visualise resources and how they relate to one another across accounts' },
                { text: 'Cost & Usage Analysis', info: 'Visualise and report on cost and usage across accounts' },
              ]}
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, paddingTop: { xs: 0, md: 2 } }}>
            <Plan
              recommended
              containerProps={{ sx: { paddingTop: { xs: 2, md: 0 }, paddingRight: { xs: 0, md: 2 } }}}
              plan="Team"
              icon={faTelescope}
              cost="£99"
              tag="Billed Monthly"
              blurb="Best for teams and small businesses with multiple users"
              features={[
                { text: 'Many Users with Team management', info: 'Add and remove users across multiple Organisations and Teams' },
                { text: '5+ AWS Accounts', info: 'An initial set of 5 AWS Accounts with the ability to add more later charged per account' },
                { text: 'All Regions', info: 'Search resources from all over the globe' },
                { text: 'Resource Inventory', info: 'Search, filter and compare resources across accounts' },
                { text: 'Architecture Visualisation', info: 'Visualise resources and how they relate to one another across accounts' },
                { text: 'Cost & Usage Analysis + Reporting', info: 'Visualise and report on cost and usage across accounts' },
              ]}
            />
            <Plan
              containerProps={{ sx: { paddingTop: { xs: 2, md: 0 } }}}
              plan="Enterprise"
              icon={faSatellite}
              cost="Custom"
              tag="Tailored to your needs"
              blurb="Ideal for large scale businesses with multiple teams"
              features={[
                { text: 'Unlimited Users with Team management', info: 'Add and remove users across multiple Organisations and Teams' },
                { text: 'Unlimited AWS Accounts', info: 'Charged per account' },
                { text: 'All Regions', info: 'Search resources from all over the globe' },
                { text: 'Resource Inventory', info: 'Search, filter and compare resources across accounts' },
                { text: 'Architecture Visualisation', info: 'Visualise resources and how they relate to one another across accounts' },
                { text: 'Cost & Usage Analysis + Reporting', info: 'Visualise and report on cost and usage across accounts' },
              ]}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default memo(Pricing, isEqual);
