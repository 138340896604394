import {Box, Container, TextField, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import isEqual from 'lodash/isEqual';
import React, {memo, useState} from 'react';
import { UnpackNestedValue, useForm } from 'react-hook-form';

import useSubscribe from '../../services/Subscription';
import FormStatus, {Message} from "../../components/FormStatus";

interface SubscriptionFormData {
  email: string;
}

function SubscribeForm() {
  const { register, handleSubmit, reset } = useForm<SubscriptionFormData>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [errors, setErrors] = useState<Error[]>([]);
  const subscribe = useSubscribe(reset, setMessages, setErrors);
  return <>
    <FormStatus messages={messages} errors={errors} sx={{ padding: 1 }} />
    <form onSubmit={handleSubmit((data: UnpackNestedValue<SubscriptionFormData>) => subscribe(data.email))}>
      <TextField
          id="email"
          fullWidth
          color="primary"
          placeholder="Your email..."
          type="email"
          {...register('email')}
          InputProps={{
            sx: {
              backgroundColor: t => t.palette.background.default,
              borderRadius: '50vh',
              outline: t => `1px solid ${t.palette.grey["500"]}`,
              '&::placeholder': {
                color: t => t.palette.background.paper,
              },
              '& input + fieldset': {
                borderColor: t => `${t.palette.background.paper} !important`,
              }
            }
          }}
      />
      <Box pt={4}>
        <Button id="subscribe" variant="contained" size="large" type="submit" disableElevation sx={{ textTransform: 'none', fontSize: '1.25em' }}>
          Subscribe
        </Button>
      </Box>
    </form>
  </>
}

function Subscribe() {
  return (
    <Box pt={12} pb={6} textAlign="center">
      <Container maxWidth="sm" >
      <Typography variant="h3" sx={{ textAlign: "center", fontSize: '2.5em', color: t => t.palette.background.paper }}>
        Stay informed
      </Typography>
      <Typography pt={2} pb={4} sx={{ textAlign: "center", color: t => t.palette.grey["500"] }}>
        Subscribe by entering your details below and we&apos;ll keep you updated of our progress via an email newsletter.
      </Typography>
      <SubscribeForm />
      </Container>
    </Box>
  );
}

export default memo(Subscribe, isEqual);
