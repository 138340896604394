import {Box, Container} from '@material-ui/core';
import React, { memo } from 'react';
import isEqual from 'lodash/isEqual';
import { Outlet } from 'react-router-dom';
import Logo from "./Logo";

function Brand() {
  return (
      <Box sx={{ paddingX: 2, paddingY: 1 }}>
        <Container maxWidth="lg" >
          <Logo />
          <Outlet />
        </Container>
      </Box>
  );
}

export default memo(Brand, isEqual);
