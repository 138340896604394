import {atom, useRecoilValue, useSetRecoilState} from 'recoil';
import {
  AuthenticationDetails, CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser, CognitoUserAttribute,
  CognitoUserSession
} from "amazon-cognito-identity-js";
import axios from "axios";
import qs from 'qs';
import { useNavigate } from "react-router-dom";
import {useEffect} from "react";
import {authEndpoint, redirectUrl, userPool} from "../Environment";

interface User {
  username: string;
  email: string;
  jwtToken: string;
}

const userState = atom<User | undefined>({
  key: 'userState',
  default: undefined,
});

const extractUserFrom = (session: CognitoUserSession) => {
  const { 'cognito:username': username, email } = session.getIdToken().payload;
  return {
    username,
    email,
    jwtToken: session.getIdToken().getJwtToken(),
  };
};

export const useLogin = (setErrors: Function) => {
  const setUser = useSetRecoilState(userState);
  return async (cognitoUsername: string, password: string) => {
    try {
      const session = await new Promise<CognitoUserSession>((resolve, reject) => {
        new CognitoUser({
          Username: cognitoUsername,
          Pool: userPool,
        }).authenticateUser(
            new AuthenticationDetails({
              Username: cognitoUsername,
              Password: password,
            }),
            {
              onSuccess: (userSession) => resolve(userSession),
              onFailure: (error) => reject(error),
            },
        );
      });
      setUser(extractUserFrom(session));
    } catch (error) {
      setErrors([error]);
    }
  };
};

const useLogout = () => {
  const navigate = useNavigate();
  return () => {
    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
      currentUser.signOut();
    }
    navigate('/login');
  };
};

const getSession = (user: CognitoUser) =>
    new Promise<CognitoUserSession>((resolve, reject) => {
      user.getSession((error: Error | null, session: CognitoUserSession) => {
        if (error) {
          return reject(error);
        }
        return resolve(session);
      });
    });

const getRefreshedSession = (user: CognitoUser, refreshToken: CognitoRefreshToken) =>
    new Promise<CognitoUserSession>((resolve, reject) => {
      user.refreshSession(refreshToken, (error: Error | null, session: CognitoUserSession) => {
        if (error) {
          return reject(error);
        }
        return resolve(session);
      });
    });

export const useApplySession = () => {
  const setUser = useSetRecoilState(userState);
  const logout = useLogout();
  return async () => {
    try {
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        const session = await getSession(cognitoUser);
        if (session.isValid()) {
          setUser(extractUserFrom(session));
          return;
        }
        const refreshedSession = await getRefreshedSession(cognitoUser, session.getRefreshToken());
        setUser(extractUserFrom(refreshedSession));
        return;
      }
      setUser(undefined);
      return;
    } catch (error) {
      logout();
    }
  };
};

export const useGetFacebookSession = (setErrors: Function) => {
  const applySession = useApplySession();
  return async (code: string) => {
    try {
      const response = await axios.request({
        url: `${authEndpoint}/oauth2/token`,
        method: 'post',
        data: qs.stringify({
          grant_type: 'authorization_code',
          client_id: userPool.getClientId(),
          redirect_uri: redirectUrl,
          code,
        }),
      });
      const idToken = new CognitoIdToken({ IdToken: response.data.id_token });
      new CognitoUser({
        Username: idToken.payload['cognito:username'],
        Pool: userPool,
      }).setSignInUserSession(
          new CognitoUserSession({
            IdToken: idToken,
            AccessToken: new CognitoAccessToken({
              AccessToken: response.data.access_token,
            }),
            RefreshToken: new CognitoRefreshToken({
              RefreshToken: response.data.refresh_token,
            }),
          }),
      );
      await applySession();
    } catch (error) {
      setErrors([error]);
    }
  };
};

const signUpUser = (username: string, email: string, password: string) =>
    new Promise((resolve, reject) => {
      userPool.signUp(username, password, [new CognitoUserAttribute({ Name: 'email', Value: email })], [], (error, data) => {
        if (error) reject(error);
        resolve(data);
      });
    });

export const useSignUp = (setErrors: Function) => {
  const navigate = useNavigate();

  return async (username: string, email: string, password: string) => {
    try {
      await signUpUser(username, email, password);
      navigate('/login?confirm=true');
    } catch (error) {
      setErrors([error]);
    }
  };
};

const forgetPassword = (username: string) =>
    new Promise<void>((resolve, reject) => {
      new CognitoUser({ Username: username, Pool: userPool }).forgotPassword({
        onSuccess: () => {
          resolve();
        },
        onFailure: (error) => {
          reject(error);
        },
      });
    });

export const useForgotPassword = (setErrors: Function) => {
  const navigate = useNavigate();

  return async (username: string) => {
    try {
      await forgetPassword(username);
      navigate('/login');
    } catch (error) {
      setErrors([error]);
    }
  };
};

const confirmPassword = (username: string, verificationCode: string, password: string) =>
    new Promise<void>((resolve, reject) => {
      new CognitoUser({ Username: username, Pool: userPool }).confirmPassword(verificationCode, password, {
        onSuccess: () => {
          resolve();
        },
        onFailure: (error) => {
          reject(error);
        },
      });
    });

export const useChangePassword = (setErrors: Function) => {
  const navigate = useNavigate();

  return async (verificationCode: string, username: string, password: string) => {
    try {
      await confirmPassword(username, verificationCode, password);
      navigate('/login');
    } catch (error) {
      setErrors([error]);
    }
  };
};

export const useSession = () => {
  const applySession = useApplySession();

  useEffect(() => {
    const interval = setInterval(() => {
      applySession();
    }, 300000);

    return () => clearInterval(interval);
  }, []);
};


export const useUser = () => useRecoilValue(userState);
