import { Alert, Box, Typography } from '@material-ui/core';
import { isEqual } from 'lodash';
import React, { memo } from 'react';
import {AlertColor} from "@material-ui/core/Alert/Alert";
import {SxProps} from "@material-ui/system";

export interface Message {
  key: string;
  text: string;
  severity?: AlertColor;
}

interface MessagesProps {
  messages?: Message[],
  errors?: Error[],
  sx?: SxProps
}

function FormStatus({ messages, errors, sx }: MessagesProps) {
  return (
    <Box id="notifications" sx={{ ...sx }}>
      <Box>
        {errors && errors.length > 0 && (
          <Box sx={{ paddingBottom: (t) => t.spacing(2), '&:last-child': { paddingBottom: 0 } }}>
            <Alert severity="error">
              {errors.map((error: Error) => (
                <Typography key={error.name ? error.name : error.message}>{error.message}</Typography>
              ))}
            </Alert>
          </Box>
        )}
      </Box>

      <Box>
        {messages && messages.length > 0 && (
          <Box sx={{ paddingBottom: (t) => t.spacing(2), '&:last-child': { paddingBottom: 0 } }}>
            <Alert severity={messages.filter((x) => x.severity).find((x) => x)?.severity ?? 'info'}>
              {messages.map((message) => (
                <Typography key={message.key}>{message.text}</Typography>
              ))}
            </Alert>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default memo(FormStatus, isEqual);
