import { Box } from '@material-ui/core';
import React, { memo, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { Outlet, useNavigate } from 'react-router-dom';
import {useSession, useUser} from '../services/Authentication';
import Sidebar from "./Sidebar";

function Navigation() {
  const user = useUser();
  const navigate = useNavigate();
  useSession();
  useEffect(() => {
    if (!user) {
      navigate(`/login?redirect=${encodeURIComponent(window.location.pathname + window.location.search + window.location.hash)}`, { replace: true });
    }
  }, [user]);
  return (
    <Box display="flex" flex={1} minHeight={0} sx={{ bgcolor: (t) => t.palette.background.default }}>
      <Sidebar />
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
}

export default memo(Navigation, isEqual);
