import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/icons.css';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@material-ui/core';
import { theme } from './theme';
import RouteList from './components/Routes';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <RecoilRoot>
          <BrowserRouter>
            <CssBaseline />
            <RouteList />
          </BrowserRouter>
        </RecoilRoot>
      </StyledEngineProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
