import { Box, Container } from '@material-ui/core';
import React, { memo } from 'react';
import isEqual from 'lodash/isEqual';
import Header from './Header';
import Introduction from './Introduction';
import Features from './Features';
import Pricing from './Pricing';
import Subscribe from "./Subscribe";

function Landing() {
  return (
    <Box sx={{ background: '#171721' }}>
      <Container maxWidth="lg">
        <Header />
        <Introduction />
      </Container>
      <Features />
      <Pricing />
      <Subscribe />
    </Box>
  );
}

export default memo(Landing, isEqual);
