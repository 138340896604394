import { faLockAlt, faUser } from '@fortawesome/pro-light-svg-icons';
import { Box, Button } from '@material-ui/core';
import React, {useRef, useState} from 'react';
import { UnpackNestedValue, useForm } from 'react-hook-form';

import IconInput from '../../components/IconInput';
import SocialLogin from '../../components/SocialLogin';
import FormStatus from "../../components/FormStatus";
import {useQueryParameter} from "../../services/QueryString";
import {useChangePassword} from "../../services/Authentication";

interface ChangePasswordFormData {
  verificationCode: string;
  username: string;
  password: string;
  confirmPassword: string;
}

function ChangePasswordForm() {

  const [errors, setErrors] = useState<Error[]>([]);
  const changePassword = useChangePassword(setErrors);
  const username = useQueryParameter('username')[0];
  const code = useQueryParameter('code')[0];
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors: fieldErrors },
    watch,
  } = useForm<ChangePasswordFormData>();
  const password = useRef({});
  password.current = watch('password');
  setValue('username', username);
  setValue('verificationCode', code);
  return (
    <Box pt={2}>
      <FormStatus errors={errors} />
      <form onSubmit={handleSubmit((data: UnpackNestedValue<ChangePasswordFormData>) => changePassword(data.verificationCode, data.username, data.password))}>
        <IconInput label="Username" icon={faUser} containerProps={{ display: 'none' }} textFieldProps={{ ...register('username'), value: username, type: 'hidden' }} />
        <IconInput label="Verification Code" icon={faUser} containerProps={{ display: 'none' }} textFieldProps={{ ...register('verificationCode'), value: code, type: 'hidden' }} />
        <IconInput
          label="Password"
          icon={faLockAlt}
          textFieldProps={{
            ...register('password'),
            type: 'password',
            helperText: 'Your password must be at least 8 characters and contain at least one letter, one number and one special character',
            inputProps: {
              pattern: '^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$',
              title: 'Must be at least 8 characters and contain at least one letter, one number and one special character',
            },
          }}
        />
        <IconInput
          label="Confirm Password"
          icon={faLockAlt}
          containerProps={{ pt: 3 }}
          textFieldProps={{
            ...register('confirmPassword', {
              validate: (value) => value === password.current,
            }),
            type: 'password',
            error: !!fieldErrors.confirmPassword,
            helperText: fieldErrors.confirmPassword && 'Must match password',
          }}
        />
        <Box pt={4} display="flex">
          <Box flex={1}>
            <Button type="submit" variant="outlined" size="large">
              Change Password
            </Button>
          </Box>
          <SocialLogin />
        </Box>
      </form>
    </Box>
  );
}

export default ChangePasswordForm;
