import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, TextField, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { TextFieldProps } from '@material-ui/core/TextField/TextField';
import { SxProps, SystemProps } from '@material-ui/system';
import React from 'react';

interface LoginInputProps {
  label: string;
  icon: IconProp;
  required?: boolean;
  containerProps?: SystemProps<Theme> & {
    sx?: SxProps<Theme>;
  };
  textFieldProps?: TextFieldProps;
}

function IconInput({ label, icon, containerProps, textFieldProps, required }: LoginInputProps) {
  return (
    <Box {...containerProps}>
      <Typography variant="body2" pl={2} sx={{ color: (t) => t.palette.primary.main, paddingBottom: (t) => t.spacing(1) }}>
        {label}
      </Typography>
      <TextField
        {...textFieldProps}
        required={required !== false}
        fullWidth
        placeholder={label}
        variant="outlined"
        InputProps={{
          sx: { color: (t) => t.palette.text.primary },
          startAdornment: (
            <Box pl={1} sx={{ fontSize: (t) => t.spacing(3), color: (t) => t.palette.text.disabled }}>
              <FontAwesomeIcon icon={icon} />
            </Box>
          ),
        }}
      />
    </Box>
  );
}

export default IconInput;
