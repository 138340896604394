import { Box, Typography } from '@material-ui/core';
import React, { memo } from 'react';
import isEqual from 'lodash/isEqual';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

interface BrandProps {
  light?: boolean;
}

function Logo({ light }: BrandProps) {
  return (
      <Box
        component={NavLink}
        to="/"
        sx={{
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          color: (t) => (light ? t.palette.background.paper : t.palette.primary.main),
        }}
      >
        <Box className={clsx('icon', 'icon-klouds-logo')} sx={{ fontSize: (t) => t.spacing(8) }} />
        <Box pl={1}>
          <Typography variant="subtitle2">klouds.io</Typography>
        </Box>
      </Box>
  );
}

export default memo(Logo, isEqual);
