import { Box, Container, Divider, lighten, Theme, Typography } from '@material-ui/core';
import { SxProps } from '@material-ui/system';
import isEqual from 'lodash/isEqual';
import React, { memo, PropsWithChildren } from 'react';

import { faChartNetwork, faCircle, faEye, faInventory, faLink, faList, faSearch, faSearchDollar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import visualize from '../../assets/image/visualize.png';
import inventory from '../../assets/image/inventory.png';

interface FeatureProps {
  icon: IconDefinition;
  title: string;
  description: string;
  sx?: SxProps<Theme>;
}

interface BreakdownProps {
  title: string;
  description: string;
  image: string;
  alt: string;
  right?: boolean;
}

interface PointProps {
  title: string;
  description: string;
  icon: IconDefinition;
}

function Feature({ icon, title, description, sx }: FeatureProps) {
  return (
    <Box flex={1} px={2} py={6} m={2} sx={{ ...sx, border: (t) => `1px solid ${t.palette.grey['300']}` }}>
      <Box component={FontAwesomeIcon} icon={icon} size="3x" sx={{ color: (t) => t.palette.primary.main }} />
      <Typography pt={4} variant="h4" sx={{ fontSize: '1.5em' }}>
        {title}
      </Typography>
      <Typography pt={2} variant="h5" sx={{ fontSize: '1em', color: (t) => t.palette.grey['500'] }}>
        {description}
      </Typography>
    </Box>
  );
}

function Breakdown({ title, description, children, image, alt, right }: PropsWithChildren<BreakdownProps>) {
  return (
    <Box pt={6} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
      <Box flex={1} sx={{ order: { xs: 0, md: right ? 1 : 0 } }}>
        <Typography pb={2} variant="h5" textAlign="start">
          {title}
        </Typography>
        <Divider sx={{ width: '5em', border: (t) => `1px solid ${t.palette.primary.main}` }} />
        <Typography pt={2} pb={3} sx={{ textAlign: 'start', color: (t) => t.palette.grey['500'] }}>
          {description}
        </Typography>
        {children}
      </Box>
      <Box flex={1} p={2} sx={{ paddingLeft: { xs: 2, md: right ? 2 : 8 }, paddingRight: { xs: 2, md: right ? 8 : 2 } }}>
        <img width="100%" height="auto" src={image} alt={alt} />
      </Box>
    </Box>
  );
}

function Point({ title, description, icon }: PointProps) {
  return (
    <Box pb={2} display="flex">
      <Box>
        <Box component="span" className="fa-stack">
          <Box component={FontAwesomeIcon} icon={faCircle} className="fa-stack-2x" sx={{ color: (t) => lighten(t.palette.primary.main, 0.8) }} />
          <Box component={FontAwesomeIcon} icon={icon} className="fa-stack-1x" sx={{ color: (t) => t.palette.primary.main }} />
        </Box>
      </Box>
      <Box pl={2} textAlign="start">
        <Typography variant="h6">{title}</Typography>
        <Typography pt={1} sx={{ color: (t) => t.palette.grey['500'] }}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
}

function Features() {
  return (
    <Box id="features" pt={16} sx={{ textAlign: 'center', background: (t) => t.palette.background.paper }}>
      <Container maxWidth="lg">
        <Typography pb={1} variant="h2" sx={{ fontSize: '1.25em', color: (t) => t.palette.primary.main }}>
          Features
        </Typography>
        <Typography pb={2} variant="h3" sx={{ fontSize: '1.5em' }}>
          Key Features Of The Product
        </Typography>
        <Divider sx={{ margin: '0 35%' }} />
        <Container maxWidth="sm">
          <Typography pt={2} sx={{ color: (t) => t.palette.grey['500'] }}>
            Empower team members to discover insights hidden in your infrastructure. Get a handle on your cloud usage with actionable intelligence.
          </Typography>
        </Container>
        <Box pt={4} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
          <Feature icon={faChartNetwork} title="Visualize" description="Investigate your infrastructure with in-depth visualizations" sx={{ marginLeft: 0 }} />
          <Feature icon={faInventory} title="Inventory" description="See cloud resources in detail across accounts in one place" />
          <Feature icon={faSearchDollar} title="Cost analytics" description="Trace, analyse and deep dive into your cloud expenditures" sx={{ marginRight: 0 }} />
        </Box>
        <Breakdown
          title="Unparalleled cloud exploration"
          description="Comprehensive graph based representation of your cloud resources across accounts to help connect people with knowledge that matters"
          image={visualize}
          alt="Showcase screenshot of the klouds.io visualize screen"
        >
          <Point icon={faSearch} title="Expose resource utilization" description="Scrutinize, filter and comprehend hidden niches and complexities within your infrastructure" />
          <Point icon={faLink} title="Identify relationships" description="Clearly observe detailed relationships between components adding hidden context and understanding" />
        </Breakdown>
        <Breakdown
          right
          title=" Quick accessable resource information"
          description="Enriched resource information providing fast access, swift searchability & comprehensive filtering"
          image={inventory}
          alt="Showcase screenshot of the klouds.io inventory screen"
        >
          <Point
            icon={faList}
            title="Investigate, monitor, analyze"
            description="Leverage extensive search and filtering capabilities to browse your resources. Investigate and act on data fast and seamlessly."
          />
          <Point icon={faEye} title="Observability" description="Monitor your entire infrastructure, track trends and gain deep understanding" />
        </Breakdown>
      </Container>
    </Box>
  );
}

export default memo(Features, isEqual);
