import axios from 'axios';

export const unauthenticatedAxios = (url: string) => ({
  call: async (method: any, resource: any, path: string, body: any, pathParameters: any, queryParameters: any, multiQueryParameters: any, headers: any) => {
    const result = await axios(url + path, {
      method: method as any,
      data: body,
      params: { ...queryParameters, ...multiQueryParameters },
      headers,
      transformResponse: [],
    });
    return {
      statusCode: result.status,
      body: result.data,
      headers: result.headers as any,
    };
  },
});
