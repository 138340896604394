import {Box, Theme, useTheme} from "@material-ui/core";
import React, {memo} from "react";
import isEqual from "lodash/isEqual";
import clsx from "clsx";
import {
  faBuilding,
  faChartBar,
  faChartNetwork,
  faCloud, faDollarSign,
  faEnvelope,
  faList
} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Gravatar from 'react-gravatar';
import {useUser} from "../services/Authentication";

function Sidebar() {
  const user = useUser();
  const theme = useTheme<Theme>();
  return <Box sx={{ backgroundColor: t => t.palette.primary.main }} display="flex" flexDirection="column">
    <Box
        className={clsx('icon', 'icon-klouds-logo')}
        sx={{
          color: (t) => t.palette.common.white,
          fontSize: t => t.spacing(4),
          padding: t => t.spacing(1.5, 1)
        }}
    />
    <Box>
      <Box sx={{ color: t => t.palette.background.paper, "&:hover": { backgroundColor: t => t.palette.primary.dark }, padding: t => t.spacing(0.5, 1), textAlign: "center", cursor: "pointer", fontSize: t => t.spacing(3) }}>
        <FontAwesomeIcon icon={faCloud} />
      </Box>
    </Box>
    <Box flex={1} display="flex" flexDirection="column" justifyContent="center">
      <Box sx={{ color: t => t.palette.background.paper, "&:hover": { backgroundColor: t => t.palette.primary.dark }, padding: t => t.spacing(0.5, 1), textAlign: "center", cursor: "pointer", fontSize: t => t.spacing(3) }}>
        <FontAwesomeIcon icon={faChartBar} />
      </Box>
      <Box sx={{ color: t => t.palette.background.paper, "&:hover": { backgroundColor: t => t.palette.primary.dark }, padding: t => t.spacing(0.5, 1), textAlign: "center", cursor: "pointer", fontSize: t => t.spacing(3) }}>
        <FontAwesomeIcon icon={faList} />
      </Box>
      <Box sx={{ color: t => t.palette.background.paper, "&:hover": { backgroundColor: t => t.palette.primary.dark }, padding: t => t.spacing(0.5, 1), textAlign: "center", cursor: "pointer", fontSize: t => t.spacing(3) }}>
        <FontAwesomeIcon icon={faChartNetwork} />
      </Box>
      <Box sx={{ color: t => t.palette.background.paper, "&:hover": { backgroundColor: t => t.palette.primary.dark }, padding: t => t.spacing(0.5, 1), textAlign: "center", cursor: "pointer", fontSize: t => t.spacing(3) }}>
        <FontAwesomeIcon icon={faDollarSign} />
      </Box>
    </Box>
    <Box sx={{ paddingBottom: t => t.spacing(1) }}>
      <Box sx={{ color: t => t.palette.background.paper, "&:hover": { backgroundColor: t => t.palette.primary.dark }, padding: t => t.spacing(0.5, 1), textAlign: "center", cursor: "pointer", fontSize: t => t.spacing(3) }}>
        <FontAwesomeIcon icon={faBuilding} />
      </Box>
      <Box sx={{ color: t => t.palette.background.paper, "&:hover": { backgroundColor: t => t.palette.primary.dark }, padding: t => t.spacing(0.5, 1), textAlign: "center", cursor: "pointer", fontSize: t => t.spacing(3) }}>
        <FontAwesomeIcon icon={faEnvelope} />
      </Box>
      <Box sx={{ color: t => t.palette.background.paper, "&:hover": { backgroundColor: t => t.palette.primary.dark }, padding: t => t.spacing(0.5, 1), textAlign: "center", cursor: "pointer", fontSize: t => t.spacing(3) }}>
        <Gravatar email={user?.email ?? ''} default="mm" width={theme.spacing(3)} height={theme.spacing(3)} style={{ borderRadius: theme.spacing(2.5) }} />
      </Box>
    </Box>
  </Box>
}

export default memo(Sidebar, isEqual);
