import { faUser } from '@fortawesome/pro-light-svg-icons';
import { Box, Button } from '@material-ui/core';
import React, {useState} from 'react';
import { UnpackNestedValue, useForm } from 'react-hook-form';

import IconInput from '../../components/IconInput';
import SocialLogin from '../../components/SocialLogin';
import {useForgotPassword} from "../../services/Authentication";
import FormStatus from "../../components/FormStatus";

interface ForgotPasswordFormData {
  username: string;
}

function ForgotPasswordForm() {
  const [errors, setErrors] = useState<Error[]>([]);
  const forgotPassword = useForgotPassword(setErrors);
  const { register, handleSubmit } = useForm<ForgotPasswordFormData>();
  return (
    <Box pt={2}>
      <FormStatus errors={errors} />
      <form onSubmit={handleSubmit((data: UnpackNestedValue<ForgotPasswordFormData>) => forgotPassword(data.username))}>
        <IconInput label="Username" icon={faUser} textFieldProps={{ ...register('username') }} />
        <Box pt={4} display="flex">
          <Box flex={1}>
            <Button type="submit" variant="outlined" size="large">
              Create Account
            </Button>
          </Box>
          <SocialLogin />
        </Box>
      </form>
    </Box>
  );
}

export default ForgotPasswordForm;
