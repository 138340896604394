import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { NavLink } from 'react-router-dom';

import logo from '../assets/image/logo.svg';

interface AugmentProps {
  action: string;
  to: string;
}

function Augment({ action, to }: AugmentProps) {
  return (
    <Box
      sx={{
        display: {
          xs: 'none',
          md: 'flex',
        },
        flexDirection: 'column',
        position: 'relative',
        '&::before': {
          content: `"g"`,
          background: `url(${logo}) no-repeat`,
          backgroundSize: '25em',
          backgroundPosition: 'bottom',
          position: 'absolute',
          top: '0',
          right: '0',
          bottom: '0',
          left: '0',
          opacity: '0.2',
          zIndex: '0',
        },
        backgroundColor: (t) => t.palette.primary.main,
        borderRadius: (t) => t.spacing(2, 0, 0, 2),
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, padding: (t) => t.spacing(4), zIndex: '1' }}>
        <Box pb={1} display="flex" alignItems="center" justifyContent="center">
          <Box className={clsx('icon', 'icon-klouds-logo')} sx={{ color: (t) => t.palette.background.paper, fontSize: (t) => t.spacing(4) }} />
          <Box pl={1}>
            <Typography variant="subtitle2" sx={{ color: (t) => t.palette.background.paper }}>
              klouds.io
            </Typography>
          </Box>
        </Box>
        <Typography variant="body2" sx={{ color: (t) => t.palette.background.paper, textAlign: 'center' }}>
          Unparalleled Insights
        </Typography>
        <Box py={4} display="flex" justifyContent="center">
          <Button
            component={NavLink}
            to={to}
            variant="outlined"
            size="large"
            sx={{
              color: (t) => t.palette.background.paper,
              textTransform: 'none',
              fontWeight: 300,
              borderRadius: '50vh',
              borderColor: (t) => t.palette.background.paper,
              '&:hover': {
                borderColor: (t) => t.palette.background.paper,
              },
            }}
          >
            {action}
          </Button>
        </Box>
        <Box flex={1} />
        <Box>
          <Box>
            <Box pb={2} display="flex" alignItems="center" sx={{ color: (t) => t.palette.background.paper }}>
              <FontAwesomeIcon icon={faCheckCircle} />
              <Typography variant="body2" sx={{ paddingLeft: (t) => t.spacing(1) }}>
                Architecture Visualisation
              </Typography>
            </Box>
            <Box pb={2} display="flex" alignItems="center" sx={{ color: (t) => t.palette.background.paper }}>
              <FontAwesomeIcon icon={faCheckCircle} />
              <Typography variant="body2" sx={{ paddingLeft: (t) => t.spacing(1) }}>
                Cost & Usage Analysis
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" sx={{ color: (t) => t.palette.background.paper }}>
              <FontAwesomeIcon icon={faCheckCircle} />
              <Typography variant="body2" sx={{ paddingLeft: (t) => t.spacing(1) }}>
                Resource Inventory
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Augment;
