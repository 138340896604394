import { Box, Container, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';

import showcase from '../../assets/image/showcase.png';

function Introduction() {
  return (
    <Box id="home">
      <Box pt={6}>
        <Container maxWidth="sm">
          <Typography
            variant="h1"
            sx={{
              textAlign: 'center',
              fontSize: (t) => t.spacing(6),
              color: (t) => t.palette.background.paper,
            }}
          >
            Cloud insights, know more about your resources
          </Typography>
        </Container>
      </Box>
      <Box pt={4}>
        <Container maxWidth="sm">
          <Typography
            sx={{
              textAlign: 'center',
              lineHeight: (t) => t.spacing(4),
              color: (t) => t.palette.grey['500'],
            }}
          >
            Visualize & evaluate cloud resources across multiple accounts enabling unparalleled organisational & infrastructural awareness
          </Typography>
        </Container>
      </Box>
      <Box pt={4} sx={{ textAlign: 'center' }}>
        <Button id="sign-up" component={NavLink} variant="contained" size="large" to="/sign-up">
          Get started now
        </Button>
      </Box>
      <Box
        pt={3}
        sx={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          color: (t) => t.palette.background.paper,
        }}
      >
        <Typography pr={1}>Free account</Typography>
        <Typography pr={1} sx={{ color: (t) => t.palette.grey['500'] }}>
          |
        </Typography>
        <Typography pr={1}>No credit card needed</Typography>
        <Typography pr={1} sx={{ color: (t) => t.palette.grey['500'] }}>
          |
        </Typography>
        <Typography pr={1}>Cancel anytime</Typography>
      </Box>
      <Box pt={6} mb={-8}>
        <img width="100%" height="auto" src={showcase} alt="Showcase screenshot of the klouds.io dashboard" />
      </Box>
    </Box>
  );
}

export default memo(Introduction, isEqual);
