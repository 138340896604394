import React, { memo } from 'react';
import isEqual from 'lodash/isEqual';
import { Routes, Route } from 'react-router-dom';
import Landing from '../features/Landing';
import Login from '../features/Login';
import SignUp from '../features/SignUp';
import ForgotPassword from '../features/ForgotPassword';
import Dashboard from '../features/Dashboard';
import ChangePassword from "../features/ChangePassword";
import Navigation from './Navigation';
import Brand from './Brand';

function RouteList() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route element={<Brand />}>
        <Route path="/login" element={<Login />} />
        <Route path="/create-account" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/change-password" element={<ChangePassword />} />
      </Route>
      <Route element={<Navigation />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/connections" element={<Dashboard />} />
      </Route>
    </Routes>
  );
}

export default memo(RouteList, isEqual);
