import { Box, Container, Typography } from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';

import Augment from '../../components/Augment';
import { useUser, useApplySession, useGetFacebookSession } from '../../services/Authentication';
import LoginForm from './LoginForm';


function Login() {
  const { search } = useLocation();
  const user = useUser();
  const navigate = useNavigate();
  const applySession = useApplySession();
  const [errors, setErrors] = useState<Error[]>([]);
  const getFacebookSession = useGetFacebookSession(setErrors);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const username = urlSearchParams.get("username");
    const code = urlSearchParams.get("code");
    if (!username && code) {
      getFacebookSession(code);
    } else {
      applySession();
    }
  }, [search]);

  useEffect(() => {
    if(user) {
      const query = new URLSearchParams(search);
      const redirect = query.get('redirect');
      navigate(redirect ? {
            pathname: redirect.split('?')[0],
            search: redirect.split('?')[1],
            hash: redirect.split('#')[1],
          }
          : {pathname: '/connections'});
    }
  }, [user, search])

  return (
      <Box>
        <Container maxWidth="md" sx={{ paddingTop: (t) => t.spacing(4) }}>
          <Box display="flex" sx={{ backgroundColor: (t) => t.palette.background.paper, borderRadius: (t) => t.spacing(2) }}>
            <Augment action="Create Account" to="/create-account" />
            <Box p={4} flex={1}>
              <Box display="flex">
                <Typography variant="subtitle2" sx={{ color: (t) => t.palette.primary.main }}>
                  Login
                </Typography>
                <Typography variant="subtitle2" sx={{ color: (t) => t.palette.text.disabled, paddingLeft: (t) => t.spacing(1) }}>
                  /
                </Typography>
                <Box component={NavLink} to="/create-account" sx={{ display: 'flex', textDecoration: 'none' }}>
                  <Typography variant="subtitle2" sx={{ color: (t) => t.palette.text.disabled, paddingLeft: (t) => t.spacing(1) }}>
                    Create Account
                  </Typography>
                </Box>
              </Box>
              <LoginForm errors={errors} setErrors={setErrors} />
            </Box>
          </Box>
        </Container>
        <Box pt={2} display="flex" justifyContent="center">
          <Box component={NavLink} to="/create-account" sx={{ display: 'flex', textDecoration: 'none' }}>
            <Typography variant="body1" sx={{ color: (t) => t.palette.text.disabled }}>
              Don&apos;t have an
            </Typography>
            <Typography variant="body1" sx={{ color: (t) => t.palette.text.primary, paddingLeft: (t) => t.spacing(0.5) }}>
              account?
            </Typography>
          </Box>
          <Typography variant="body1" sx={{ color: (t) => t.palette.text.disabled, paddingLeft: (t) => t.spacing(2) }}>
            /
          </Typography>
          <Box component={NavLink} to="/forgot-password" sx={{ display: 'flex', textDecoration: 'none' }}>
            <Typography variant="body1" sx={{ color: (t) => t.palette.text.disabled, paddingLeft: (t) => t.spacing(2) }}>
              Forgot
            </Typography>
            <Typography variant="body1" sx={{ color: (t) => t.palette.text.primary, paddingLeft: (t) => t.spacing(0.5) }}>
              password?
            </Typography>
          </Box>
        </Box>
      </Box>
  );
}

export default Login;
