import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Link, Typography } from '@material-ui/core';
import React from 'react';

import { authEndpoint, redirectUrl, userPool } from '../Environment';
import IconButtonSolid from './IconButtonSolid';

function SocialLogin() {
  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body2" sx={{ color: (t) => t.palette.text.secondary, paddingRight: (t) => t.spacing(1) }}>
        Or login with
      </Typography>
      <Link href={`${authEndpoint}/oauth2/authorize?client_id=${userPool.getClientId()}&redirect_uri=${redirectUrl}&response_type=code&identity_provider=Facebook`}>
        <IconButtonSolid sx={{ color: t => t.palette.background.paper, marginLeft: (t) => t.spacing(2) }} backgroundColor={'#3b5998'}>
          <FontAwesomeIcon icon={faFacebookF} />
        </IconButtonSolid>
      </Link>
      <Link href={`${authEndpoint}/oauth2/authorize?client_id=${userPool.getClientId()}&redirect_uri=${redirectUrl}&response_type=code&identity_provider=Google`}>
        <IconButtonSolid sx={{ color: t => t.palette.background.paper, marginLeft: (t) => t.spacing(2) }} backgroundColor={'#de5246'}>
          <FontAwesomeIcon icon={faGoogle} />
        </IconButtonSolid>
      </Link>
    </Box>
  );
}

export default SocialLogin;
