import {IconButton, IconButtonProps} from '@material-ui/core';
import { Theme } from "@material-ui/core/styles";
import { SxProps } from "@material-ui/system";
import React from 'react';

interface IconButtonSolidProps extends IconButtonProps {
  sx: SxProps<Theme>;
  backgroundColor?: string;
}

function IconButtonSolid({ sx, children, backgroundColor }: React.PropsWithChildren<IconButtonSolidProps>) {
  return (
    <IconButton sx={{ ...sx, height: t => t.spacing(6), width: t => t.spacing(6), backgroundColor: backgroundColor ?? 'primary', '&:hover': { backgroundColor: backgroundColor ?? 'primary' } }}>
      {children}
    </IconButton>
  );
}

export default IconButtonSolid;
