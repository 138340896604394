import { SubscriptionServiceAPISdk } from '@hexlabsio/klouds-subscription-sdk';

import { apiEndpoint } from '../Environment';
import { unauthenticatedAxios } from './Services';

const useSubscriptionSdk = () => new SubscriptionServiceAPISdk(unauthenticatedAxios(`${apiEndpoint}/subscription`));

const useSubscribe = (reset: Function, setMessages: Function, setErrors: Function) => {
  const subscriptionSdk = useSubscriptionSdk();
  return async (email: string) => {
    try {
      await subscriptionSdk.postSubscribe(email);
      setMessages([{ key: 'subscription-success', text: 'Thank you for subscribing' }]);
      setErrors([]);
      reset();
    } catch (error) {
      setMessages([]);
      setErrors([error]);
      reset();
    }
  };
};

export default useSubscribe;
