import { faLockAlt, faUser } from '@fortawesome/pro-light-svg-icons';
import { Box, Button } from '@material-ui/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import React, {useEffect, useState} from 'react';
import { UnpackNestedValue, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { userPool } from '../../Environment';
import IconInput from '../../components/IconInput';
import FormStatus, {Message} from '../../components/FormStatus';
import SocialLogin from '../../components/SocialLogin';
import { useLogin } from "../../services/Authentication";

interface LoginFormData {
  username: string;
  password: string;
}

interface LoginFormProps {
  errors: Error[];
  setErrors: Function;
}

function LoginForm({ errors, setErrors }: LoginFormProps) {
  const { search } = useLocation();
  const { register, handleSubmit } = useForm<LoginFormData>();
  const [messages, setMessages] = useState<Message[]>([]);
  const login = useLogin(setErrors);

  const confirmUser = async (cognitoUsername: string, confirmationCode: string) => {
    new CognitoUser({
      Username: cognitoUsername,
      Pool: userPool,
    }).confirmRegistration(confirmationCode, true, (error: Error) => {
      if (error) {
        return setErrors([error]);
      }
      return setMessages([{ key: 'user-confirmed', text: 'User confirmed' }]);
    });
  };

  useEffect(() => {
    const errorDescription = new URLSearchParams(window.location.search).get("error_description");
    if (errorDescription) {
      setMessages([
        {
          key: 'facebook-permissions',
          text: 'Please update Facebook permissions to include all requested',
        },
      ]);
    }
  }, [search]);

  useEffect(() => {
    const confirm = new URLSearchParams(window.location.search).get("confirm");
    if (confirm) {
      setMessages([{ key: 'confirm-user', text: 'Please check your email to confirm' }]);
    }
  }, [search]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const username = urlSearchParams.get("username");
    const code = urlSearchParams.get("code");
    if (username && code) {
      confirmUser(`${username}`, `${code}`);
    }
  }, [search]);

  return (
      <Box pt={2}>
        <FormStatus messages={messages} errors={errors} sx={{ paddingBottom: 2 }} />
        <form onSubmit={handleSubmit((data: UnpackNestedValue<LoginFormData>) => login(data.username, data.password))}>
          <IconInput label="Username or Email" icon={faUser} textFieldProps={{ ...register('username') }} />
          <IconInput label="Password" icon={faLockAlt} containerProps={{ pt: 3 }} textFieldProps={{ ...register('password'), type: 'password' }} />
          <Box pt={4} display="flex">
            <Box flex={1}>
              <Button type="submit" variant="outlined" size="large">
                Login
              </Button>
            </Box>
            <SocialLogin />
          </Box>
        </form>
      </Box>
  );
}

export default LoginForm;
