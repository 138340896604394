import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import isEqual from "lodash/isEqual";

export const useQueryParameter = (name: string) => {
  const { search } = useLocation();
  const [values, setValues] = useState<string[]>([]);
  useEffect(() => {
    const updated = new URLSearchParams(search).getAll(name);
    if (!isEqual(values, updated)) {
      setValues(updated);
    }
  }, [search]);
  return values;
};
