import { Box, Link, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import isEqual from 'lodash/isEqual';
import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../components/Logo';

interface LinkProps {
  text: string;
  href: string;
}

function HeaderLink({ text, href }: LinkProps) {
  return (
    <Typography sx={{ marginRight: (t) => t.spacing(2), '&:hover': { cursor: 'pointer', opacity: 0.8 }, '&:last-child': { marginRight: 0 } }}>
      <Link color="white" underline="none" href={href}>
        {text}
      </Link>
    </Typography>
  );
}

function Header() {
  const links = [
    { text: 'Home', href: '#home' },
    { text: 'Features', href: '#features' },
    { text: 'Pricing', href: '#pricing' },
    { text: 'Subscription', href: '#subscribe' },
  ];

  return (
    <Box display="flex" alignItems="center" sx={{ paddingX: 2, paddingY: 1 }}>
      <Logo light />
      <Box display="flex" alignItems="center" flexGrow={1} justifyContent="flex-end" color="white">
        <Box sx={{ display: { xs: 'none', lg: 'flex' }, textAlign: 'center', marginRight: (t) => t.spacing(4) }}>
          {links.map((link) => (
            <HeaderLink key={link.text} text={link.text} href={link.href} />
          ))}
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Button component={NavLink} variant="contained" size="large" disableElevation to="/sign-up" sx={{ display: { xs: 'none', md: 'flex' }, textTransform: 'none', marginRight: 2 }}>
            Get started now
          </Button>
          <Button component={NavLink} variant="contained" size="large" disableElevation to="/login" sx={{ textTransform: 'none' }}>
            Login
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default memo(Header, isEqual);
